.row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: rgb(210, 122, 13); */
  margin: 3px;
  /* border: 1px solid blue; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.weight,
.height {
  width: 100px;
}
.cpf{
  width: 160px;
}
