.date_container {
  cursor: pointer;
}

.date_input_container {
  display: flex;
}

.date_picker {
  align-self: center;
  margin: 0 3px;
}
.show_date {
  width: 150px;
}
