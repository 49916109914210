.container {
  margin-left: 5px;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.746);
}
.checked {
  font-weight: bold;
  color: red;
}

.checkbox {
  display: none !important;
}
