.row {
  display: flex;
  margin: 10px 0;
  gap: 10px;
  align-items: center;
}
.border {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  /* width: 300px; */
}
.container {
  height: 70px;
  overflow-y: scroll;
  cursor: pointer;
  font-size: 12px;
}
.item:nth-child(odd) {
  background: rgb(240, 240, 240);
  border-radius: 4px;
}

