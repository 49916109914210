.element {
  cursor: pointer;
}
.inactive {
  color: rgba(128, 128, 128, 0.574);
}

.row {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}
.label {
  font-weight: bold;
}

.button_container {
  text-align: center;
}
