.header_input_container {
  /* background-color: aqua; */
  /* max-width: 600px; */
  width: 100%;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  color: rgb(22, 85, 137);
}
.row {
  display: flex;
  justify-content: center;
}

.text_input {
  width: 300px;
}
.item {
  margin: 0 3px;
}
.action{
  cursor: pointer;
  margin: 0 5px;
}