.header {
  background-color: rgb(197, 224, 181);
  font-weight: bold;
  text-transform: uppercase;
}
.header_food {
  background-color: rgb(247, 202, 171);
}
.header_insects {
  background-color: rgb(241, 221, 246);
}
.header_latex {
  background-color: rgb(255, 228, 151);
}

.container {
  margin: 10px auto;
  background-color: rgb(226, 238, 216);
  box-shadow: 0 2px 5px grey;
  padding: 0 0 5px 0;
}
.container_food {
  background-color: rgb(250, 227, 211);
}
.container_insects {
  background-color: rgb(246, 236, 245);
}
.container_latex {
  background-color: rgb(254, 242, 202);
}
.group_name {
  text-align: start;
  font-weight: bold;
  text-transform: uppercase;
  width: fit-content;
  width: 50%;
  padding: 0 10px;
  margin: auto 0;
  /* background-color: red; */
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6,1fr);
  grid-auto-flow: column;
  /* background-color: green; */
}

.content_food {
  /* grid-template-rows: repeat(5,1fr); */
  grid-template-rows: repeat(4,1fr);
}
.content_insects {
  /* grid-template-rows: repeat(1,1fr); */
  grid-template-rows: repeat(2,1fr);
}
.content_latex {
  display: block;
}
