.label {
  font-weight: bold;
}
.element {
  cursor: pointer;
  white-space: pre-line;
  border-bottom: 1px solid;
}
.element p {
  margin: 0;
}

.row {
  display: flex;
  margin-top: 10px;
  gap: 20px;
  width: 600px;
}
.button_container {
  text-align: center;
}
