.container {
  margin-top: 20px;
}
.box {
  border: 1px solid rgb(156, 156, 156);
  border-radius: 5px;
}
.header {
  margin: 5px 2px;
  background-color: rgb(217, 226, 243);
  color: rgb(57, 82, 141);
  text-transform: uppercase;
  font-weight: bold;
}
