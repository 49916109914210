.container {
  border: 1px solid;
  border-radius: 10px;
  padding: 15px;
  margin: 15px 5px;
}

.group_name {
  font-weight: bold;
  border-radius: 10px;
  opacity: 0.7;
}
.element {
  cursor: pointer;
}
.inactive {
  color: rgba(128, 128, 128, 0.574);
}
