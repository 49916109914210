.measurement_container {
  font-size: 10px;
  margin: 1px 15px;
  /* background-color: aquamarine; */
  position: relative;
  align-self: center;
  height: fit-content;
}

.chamber_img {
  height: 70px;
}

.measurment_label {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
}
.buttons_container {
  /* background-color: rgba(0, 128, 0, 0.335); */
  position: absolute;
  top: 8px;
  bottom: 18px;
  right: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.button {
  /* background-color: rgba(91, 29, 29, 0.431); */
  cursor: pointer;
}
.values_container {
  position: absolute;
  /* background-color: rgba(0, 128, 0, 0.811); */
  top: 9px;
  bottom: 20px;
  right: 10px;
  left: 10px;
}
.value {
  height: 20px;
  font-size: 12px;
  /* border: 1px solid; */
}
.hidden {
  display: none;
}
.red {
  color: red;
}
