.row {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}
.label {
  font-weight: bold;
}
.lab_list_container {
  border: 1px solid rgba(22, 85, 137, 0.459);
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
}
.lab_list {
  overflow-y: scroll;
  height: 400px;
  width: 400px;
  cursor: pointer;
}

.button_container {
  text-align: center;
}
