.item {
  display: flex;
  flex-direction: row;
  /* background-color: aqua; */
  align-items: center;
  text-align: start;
  padding: 0 10px;
}

.inactive {
  color: rgba(128, 128, 128, 0.783);
}

.number_input {
  height: 30px;
  flex-basis: 68px;
  margin: 0 5px;
}
.checkbox {
  margin-left: 5px;
}
