.header_input_container {
  /* background-color: aqua; */
  margin: 0 auto;
  color: rgb(22, 85, 137);
  gap: 20px;
}

.row {
  display: flex;
  justify-content: center;
}
.action{
  cursor: pointer;
  margin: 0 2px;
}