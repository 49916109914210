.container {
  position: fixed; /* Stay in place */
  bottom: 0.5cm;
  right: 0.5cm;
  z-index: 1;
  text-align: center;
}

/* The Modal (background) */
.modal_background {
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding: 30vh; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
