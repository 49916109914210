.header_input_container {
  /* background-color: aqua; */
  max-width: 600px;
  margin: 20px auto;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(22, 85, 137);
}
.row {
  display: flex;
  gap: 0 5px;
  flex-direction: column;
  /* background-color: rgb(210, 122, 13); */
  margin: 3px;
  /* border: 1px solid blue; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.label {
  width: 200px;
  padding: 10px;
}
.input_field {
  width: 600px;
}

.drops {
  width: 60px;
}

.button_container {
  /* background-color: rgb(121, 176, 157); */
  margin: 10px;
  text-align: center;
}

.diagnosis{
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
}