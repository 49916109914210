.container {
  margin: 10px auto;
}
.list_border {
  padding: 5px;
  border: 1px solid;
  border-radius: 10px;
  margin: 10px auto;
  width: 100%;
  max-width: 700px;
}
.prick_form_list {
  overflow-y: scroll;
  width: 100%;
  height: 100px;
  text-align: start;
}
.one_prick_form {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.selected {
  background-color: rgba(210, 231, 196, 0.526);
  border-radius: 4px;
}

.action {
  cursor: pointer;
  width: fit-content;
}

.item_text {
  flex: 1;
}
