.menuItem:hover,
.active:hover,
.active {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  background-color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 0.375rem;
  padding: 0 16px;
}

.logo {
  height: 50px;
}
.menuItem {
  text-decoration: underline;
  color: rgb(22, 85, 137);
  margin: 0 10px;
}
