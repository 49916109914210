.container {
  text-align: left;
  width: fit-content;
  margin: 20px auto;
}

.row {
  display: flex;
  gap: 20px;
}
.label {
  font-weight: bold;
}
.lab_list_container {
  border: 1px solid rgba(22, 85, 137, 0.459);
  border-radius: 10px;
  padding: 5px 5px 5px 10px;
}
.lab_list {
  overflow-y: scroll;
  height: 400px;
  width: 400px;
  cursor: pointer;
}

.test_container {
  margin: 20px 0;
  border: 2px solid rgba(22, 85, 137, 0.459);
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.test_container:hover {
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.2);
}

.input_text {
  width: 100%;
  margin: 0 0 10px 0;
}
.button_container {
  margin: 20px 0;
  text-align: center;
}
