.card {
  width: fit-content;
  margin: 15px auto;
  background-color: rgba(210, 231, 196, 0.526);
  color: rgb(22, 85, 137);
  border-radius: 10px;
  overflow: hidden;
  padding: 0 0 5px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.card:hover {
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.2);
}

.contensors_container {
  display: flex;
}

.contensor {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  height: 380px;
  grid-auto-flow: column;
  border: 1px solid;
  border-radius: 8px;
  margin: 10px;
  padding: 12px;
}

.four_items{
  grid-template-rows: repeat(4, 1fr);
}

.title_container {
  text-align: center;
  background-color: rgba(210, 231, 196, 0.526);
  font-size: 15px;
}
.title {
  margin: 0;
  padding: 0 10px;
}
