.container {
  margin-left: 5px;
  cursor: pointer;
  flex: 1;
  white-space: nowrap;
  margin: 8px 0 8px 2px;
}

.checkbox {
  display: none !important;
}
