.row {
  display: flex;
  flex-direction: column;
  /* background-color: rgb(210, 122, 13); */
  margin: 3px;
  /* border: 1px solid blue; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.gender{
  width: 300px;
}
.obs{
  width: 100%;
}
