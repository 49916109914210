.container {
  background-color: rgba(217, 217, 217);
  box-shadow: 0 2px 5px grey;
  padding: 0 0 5px 0;
  margin: 30px auto;
}
.header {
  text-transform: uppercase;
  background-color: rgb(191, 191, 191);
  font-weight: bold;
}
.row {
  display: flex;
  /* background-color: rgb(210, 122, 13); */
  margin: 3px;
  /* border: 1px solid blue; */
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.item {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 15px;
  /* background-color: aqua; */
  align-items: center;
}
.numer_input {
  height: 30px;
  flex-basis: 68px;
  margin: 0 5px;
}
.label {
  width: 70%;
  margin: auto;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  /* background-color: antiquewhite; */
}
